import Vue from "vue";
import Router from "vue-router";
import {
  authGuard,
  loginGuard,
  dealerGuard,
  subdivisionUserGuard, disableGuard,
} from "./guard";

Vue.use(Router);

const Account = () => import("@/views/pages/Account");
const Login = () => import("@/views/pages/Login");
const RecoverPassword = () => import("@/views/pages/RecoverPassword");

const PublicArea = () => import("@/containers/PublicAreaContainer");
const Home = () => import("@/views/pages/dealer/Home");

function configRoutes() {
  return [
    {
      path: "/",
      name: "Account",
      component: Account,
      beforeEnter: loginGuard,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/password-recovery",
          name: "RecoverPassword",
          component: RecoverPassword,
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/forbidden",
      name: "Forbidden",
      component: () => import("@/views/pages/Forbidden.vue"),
    },
    {
      path: "/blocked",
      name: "Forbidden",
      component: () => import("@/views/pages/BlockedUser.vue"),
    },
    {
      path: "/not-found-user",
      name: "Forbidden",
      component: () => import("@/views/pages/NotFoundUser.vue"),
    },
    {
      path: "/admin-login",
      name: "AdminLogin",
      component: () => import("@/views/pages/AdminLogin"),
    },
    {
      path: "",
      name: "DealerArea",
      component: PublicArea,
      beforeEnter: dealerGuard,
      children: [
        {
          path: "/home",
          name: "Home",
          beforeEnter: disableGuard,
          component: Home,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/cart",
          name: "DealerCart",
          component: () => import("@/views/pages/dealer/Cart"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "",
      name: "SubdivisionArea",
      component: PublicArea,
      beforeEnter: subdivisionUserGuard,
      children: [
        {
          path: "/subdivision-cart",
          name: "SubdivisionUserCart",
          component: () =>
            import("@/views/pages/subdivision_user/SubdivisionCart"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/work-rules",
          name: "WorkRules",
          component: () => import("@/views/pages/WorkRules"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "",
      component: PublicArea,
      children: [
        {
          path: "/catalog/:slug/:slug2?",
          name: "Catalog",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/Catalog"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/catalog/:section/:category/:product",
          name: "Product",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/ProductCard"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/successful-order",
          name: "SuccessfulOrder",
          component: () => import("@/views/pages/SuccessfulOrder"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/successful-visit-order",
          name: "SuccessfulVisit",
          component: () => import("@/views/pages/SuccessfulNewVisitOrder"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/faq",
          name: "Faq",
          component: () => import("@/views/pages/FAQ"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/delivery",
          name: "Delivery",
          component: () => import("@/views/pages/Delivery"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/contacts",
          name: "Contacts",
          component: () => import("@/views/pages/Contacts"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/about",
          name: "About",
          component: () => import("@/views/pages/About"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/how-to-order",
          name: "HowToOrder",
          component: () => import("@/views/pages/HowToOrder"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/visits",
          name: "Visits",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/Visit"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/education",
          name: "Education",
          component: () => import("@/views/pages/Education"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/education_detail/:id",
          name: "Education_detail",
          props: true,
          component: () => import("@/views/pages/education/DetailPage"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/education_request/:id",
          name: "Education_request",
          props: true,
          component: () => import("@/views/pages/education/RequestForm"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/visits/perm",
          name: "VisitPerm",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/visit/PermPage"),
        },
        {
          path: "/visits/torzhok",
          name: "VisitVolgograd",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/visit/VolgogradPage"),
        },
        {
          path: "/visits/kazakhstan",
          name: "VisitKazaxstan",
          component: () => import("@/views/pages/visit/KazaxstanPage"),
        },
        {
          path: "/visits/tyumen",
          name: "VisitTyumen",
          beforeEnter: disableGuard,
          component: () => import("@/views/pages/visit/TyumenPage"),
        },
        {
          path: "/visit-request-form/:slug",
          name: "VisitRequestForm",
          component: () => import("@/views/pages/visit/VisitRequestForm"),
        },
        {
          path: "",
          name: "Cabinet",
          component: () => import("@/views/pages/Cabinet"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "/profile",
              name: "Profile",
              component: () => import("@/views/pages/cabinet/Profile"),
            },
            {
              path: "/requests-write-down-points",
              beforeEnter: dealerGuard,
              name: "RequestsWriteDownPoints",
              component: () => import("@/views/pages/cabinet/RequestsWriteDownPoints"),
            },
            {
              path: "/product-orders",
              name: "ProductOrders",
              component: () => import("@/views/pages/cabinet/Orders"),
            },
            {
              path: "/visit-orders",
              name: "VisitOrders",
              component: () => import("@/views/pages/cabinet/VisitOrders"),
            },
            {
              path: "/points-flow",
              name: "PointsFlow",
              component: () => import("@/views/pages/cabinet/History"),
            },
            {
              path: "/profile-contacts",
              name: "ProfileContacts",
              component: () => import("@/views/pages/cabinet/Contacts"),
            },
            {
              path: "/change-password",
              name: "ChangePassword",
              component: () => import("@/views/pages/cabinet/ChangePassword"),
            },
          ],
        },
        {
          path: "*",
          name: "NotFound",
          component: () => import("@/views/pages/NotFound"),
        },
      ],
    },
  ];
}

const routes = configRoutes();

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(authGuard);

export default router;
