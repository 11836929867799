import {get, post} from '@/lib/axios';

export default {
    async login(credentials) {
        return post('/account/authenticate', credentials);
    },
    async recoverPassword(data) {
        return post('/account/recover-password', data);
    },
    async userInfo() {
        return get('/account/info');
    },
    async logout(){
        return get('/account/logout');
    }
}
