import store from '../store';
import {redirectToProperIndex} from "./helpers";
import {Dealer, StructuralSubdivisionUser} from "../config/roles";
import usersWithDisablePages from '@/config/users_with_disable_pages'
import api from "@/api";
import {removeLoading, setLoading} from "@/lib/loading";

const disableGuard = (to, from, next) => {
    const token = store.state.account.token;
    const userNumber = store.state.account.accountInfo.dealerUniqueId;

    if (!token && !userNumber) {
        next({name: 'Login'})
    } else if (usersWithDisablePages.includes(+userNumber) && to.name === 'Home') {
        next('/education')
    } else if (usersWithDisablePages.includes(+userNumber)) {
        next('/404')
    }

    next();
}

const authGuard = async (to, from, next) => {
  if (to.meta.requiresAuth && !store.state.account.token) {
    setLoading();
    if (to.path !== '/login') return next({ path: "/login" });
    try {
      const authResponse = await api.account.login({
        code: to.query.code,
      });
      if (authResponse.data.status === 'unauthorized') {
        window.location.href = authResponse.data.data.loginUrl;
      } else if (authResponse.data.status === 'blocked') {
        next({ path: "/blocked" });
      } else if (authResponse.data.status === 'notfound') {
        next({ path: "/not-found-user" });
      } else {
        store.commit('account/SET_ACCOUNT', authResponse.data.data);
        redirectToProperIndex(store.getters['account/role']);
      }
    } catch (e) {
      console.log('err', e)
      if (e.response.status === 403) {
        next({ path: "/forbidden" });
      }
    }
    removeLoading();
  }
  next()
};

const loginGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (token && role) {
        redirectToProperIndex(role);
        return;
    } else if (to.name === 'Account') {
        next({name: 'Login'})
    }

    next();
};

const dealerGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (!token || !role) {
        next({name: 'Login'})
    } else if (role != Dealer) {
        next('/404')
    }

    next();
}

const isDealerGuard = () => {
    const role = store.state.account.accountInfo.role;
    return role == Dealer
}

const subdivisionUserGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (!token || !role) {
        next({name: 'Login'})
    } else if (role != StructuralSubdivisionUser) {
        next('/404')
    }

    next();
}


export {authGuard, loginGuard, dealerGuard, subdivisionUserGuard, disableGuard, isDealerGuard};
