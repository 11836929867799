import api from '@/api';
import router from '@/router';
import {showMessage} from "@/lib/toasted";
import {setLoading, removeLoading} from "@/lib/loading";
import {redirectToProperIndex} from "@/router/helpers";
import {Dealer, StructuralSubdivisionUser} from "@/config/roles";


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token'),
        accountInfo: localStorage.getItem('accountInfo') ? JSON.parse(localStorage.getItem('accountInfo')) : {
            name: '',
            type: null,
            role: null
        },
        pointsNumber: 0,
        structuralSubdivisionName: ''
    },
    getters: {
        accountInfo: ({accountInfo}) => accountInfo,
        type: ({accountInfo: {type}}) => type,
        role: ({accountInfo: {role}}) => role,
        pointsNumber: ({pointsNumber}) => pointsNumber,
        structuralSubdivisionName: ({structuralSubdivisionName}) => structuralSubdivisionName,
        isCurrentUserStructuralSubdivisionUser: ({accountInfo: {role}}) => role == StructuralSubdivisionUser,
        isCurrentUserDealer: ({accountInfo: {role}}) => role == Dealer,
    },
    mutations: {
        SET_ACCOUNT(state, {token, name, type, role, dealerUniqueId}) {
            localStorage.setItem('token', token)
            state.token = token;

            const accountInfo = {
                name,
                type,
                dealerUniqueId,
                role
            }

            localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
            state.accountInfo = accountInfo;
        },
        SET_POINTS_NUMBER(state, points) {
            state.pointsNumber = points;
        },
        SET_STRUCTURAL_SUBDIVISION_NAME(state, name) {
            state.structuralSubdivisionName = name
        }
    },
    actions: {
        async authenticate({commit, getters}, credentials) {
            try {
                setLoading();
                const {data: {status, data}} = await api.account.login(credentials);

                if (status === 'ok') {
                    commit('SET_ACCOUNT', data);
                    const {role} = getters;

                    redirectToProperIndex(role);
                }
            } catch (e) {
                const data = e.response.data;

                if (e.response.status == 403 && data.status == 'error') {
                    showMessage(data.status, data.message)
                }
            }

            removeLoading();
        },
        async authenticateAs({commit}, data) {
            const {role} = data;

            commit('SET_ACCOUNT', data);
            redirectToProperIndex(role);
        },
        async logout ({commit}) {
            try {
                commit("SET_ACCOUNT", {});
                localStorage.removeItem('token');
                localStorage.removeItem('accountInfo');
                const logoutResponse = await api.account.logout();
                window.location.href = logoutResponse.data;
            } catch (e) {
                console.error(e)
            }
        },
        async getUserInfo({commit}) {
            try {
                setLoading();
                const {data: {data: {
                    points_number,
                    structural_subdivision_name,
                    items_in_cart = 0,
                    in_cart_product_ids = [],
                }}} = await api.account.userInfo();

                if (points_number) {
                    commit('SET_POINTS_NUMBER', points_number)
                }

                if (structural_subdivision_name) {
                    commit('SET_STRUCTURAL_SUBDIVISION_NAME', structural_subdivision_name)
                }

                commit('cart/SET_ITEMS_IN_CART', items_in_cart, {root: true})
                commit('cart/SET_IN_CART_PRODUCT_IDS', in_cart_product_ids, {root: true})
            } catch (e) {}

            removeLoading();
        }
    }
}
